/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Icons extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg section-nucleo-icons">
          <Container id="container-platform">
            <Row className="justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-1">The ZoneIt&#xAE; Platform</h2>
                <p className="lead">
                  The ZoneIt&#xAE; platform makes it easy to manage mobile pay parking, parking enforcement,
                   digital permitting, payments and more all in one place. Leverage the data insights to make informed decisions and be more efficient in policy making.
                </p>
              </Col>
            </Row>
            <div className="" style={{color: '#fb6b40'}}>
                <div className="icons-container mt-5 on-screen">
                <i className="fa fa-cc-visa icon" style={{color: 'inherit'}} aria-hidden="true" />
                <i className="fa fa-cc-mastercard icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-cc-discover icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-apple icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-google-wallet icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-cc-amex icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-map icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-bus icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-cc-stripe icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-line-chart icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                <i className="fa fa-mobile icon icon-lg" style={{color: 'inherit', fontSize: '2.5rem'}} aria-hidden="true" />
                  <i className="icon icon-lg ni ni-app" />
                  <i className="icon icon-lg ni ni-building" />
                  <i className="icon icon-lg ni ni-chart-bar-32" />
                </div>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default Icons;
