import React, {useEffect, useRef} from "react";

// reactstrap components
import {
  Card,
  CardImg,
  Container,
  Row,
  Col,
  UncontrolledCarousel
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

const pic1 = [
  {
    src: require("assets/img/theme/engineering-hero.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];
const pic2 = [
  {
    src: require("assets/img/theme/integration.jpg"),
    altText: "",
    caption: "",
    header: ""
  }
];



const WhyZoneIt = (props) => {

  const mainRef = useRef(null)
  useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainRef.current.scrollTop = 0;
  });

    return (
      <>
        <DemoNavbar />
        <main ref={mainRef}>
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pt-200 pb-200">
              <div className="shape shape-style-1 shape-default" style={{background: `linear-gradient(150deg, #fb6b40 15%, #fb6b40 70%, #fba73f 94%)`}}>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-2 text-white">
                       Why ZoneIt{" "}
                        <span classnames="text-uppercase">A Team of Innovators</span>
                      </h1>
                      <p className="lead text-white">
                      Our secure, simple solutions help our clients conquer their toughest transportation challenges. ZoneIt leverages the power of cloud infrastructure and serverless computing to
                      create highy scalable, reliable and secure applications.
                      </p>
                    </Col>
                    <Col lg="6">
                    <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel controls={false} indicators={false} items={pic1} />
                </div>
                    <div className="rounded shadow-lg overflow-hidden transform-perspective-left">
                  <UncontrolledCarousel controls={false} indicators={false} items={pic2} />
                </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <Container className="container-lg my-5">
            <Row>
              <Col md="12" className="mb-3">
                <h4 className="display-1 text-center">One end-to-end digital platform</h4>
                <p className="text-center">
                  <strong>

                One system to solve for all of your mobility needs.
                  </strong>
                </p>
              </Col>
              <Col className="mb-5 mb-md-0" md="6">
                <p className="text-left">
                ZoneIt's digital platform serves as a strategic, decision-making system that provides a single source of truth for data, analytics and insights, and is a tool that can be used to develop, implement and manage policy.
</p><p>
Our digital platform:
<br />
<br />
<ul className="pl-3">
  <li>
  Uses one system to manage rules, rates, and restrictions for all parking partners
  </li>
  <li>
  Improves your customer experience and drives equity with digital payments
  </li>
  <li>
  Boosts payment compliance for parking
  </li>
  <li>
  Digitizes revenue and reduce hardware costs
  </li>
  <li>
  Future proofs for innovation
  </li>
</ul>




                </p>
              </Col>
              <Col className="mb-5 mb-lg-0" md="6">
                <Card className="card-lift--hover shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/end-to-end.jpg")}
                    />
                </Card>
              </Col>

            </Row>
          </Container>
        </main>
        <CardsFooter />
      </>
    );
}

export default WhyZoneIt;
