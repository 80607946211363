import React, { useEffect } from "react";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardImg,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import ContactUs from "components/Forms/ContactUs";
import { useRef } from "react";

// index page sections

const PrivateOperators = props => {
    const mainRef = useRef(null)
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainRef.current.scrollTop = 0;
    });

        return (
            <>
                <DemoNavbar />
                <main ref={mainRef}>
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="section section-lg section-shaped pb-250">
                            <div className="shape shape-style-1 shape-default" style={{background: `linear-gradient(150deg, #fb6b40 15%, #fb6b40 70%, #fba73f 94%)`}}>
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>
                            <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        Modern. Integrated.
                        <br />
                        <strong> ZoneIt</strong>.
                      </h1>
                      <p className="lead text-white">
                      Harness the power of mobile, cloud-based technology to unleash innovative payment and enforcement capabilities without incurring hardware or staffing expenses.
                      </p>
                      <div className="btn-wrapper">
                      <Button
                          className="btn-white mb-3 mb-sm-0"
                          color="default"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--text">Schedule a Demo</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-app" />
                          </span>
                          <span className="btn-inner--text">
                            Explore ZoneIt
                          </span>
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                    <ContactUs size="12"/>
                    </Col>
                  </Row>
                </div>
              </Container>
                            {/* SVG separator */}
                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="2560 0 2560 100 0 100"
                                    />
                                </svg>
                            </div>
                        </section>
                        {/* 1st Hero Variation */}
                    </div>
                    <section className="section bg-secondary mb-lg">
                        <Container>
                            <Row className="row-grid align-items-center">
                                <Col md="6">
                                    <Card className="bg-default shadow border-0">
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/theme/img-1-1200x1000.jpg")}
                                            top
                                        />
                                        <blockquote className="card-blockquote">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="svg-bg"
                                                preserveAspectRatio="none"
                                                viewBox="0 0 583 95"
                                            >
                                                <polygon
                                                    className="fill-default"
                                                    points="0,52 583,95 0,95"
                                                />
                                                <polygon
                                                    className="fill-default"
                                                    opacity=".2"
                                                    points="0,42 583,95 683,0 0,95"
                                                />
                                            </svg>
                                            <h4 className="display-3 font-weight-bold text-white">
                                                The ZoneIt&#xAE; Difference
                                            </h4>
                                            <p className="lead text-italic text-white">
                                                ZoneIt's digital platform is best suited to meet the unique needs of your organization.
                                            </p>
                                        </blockquote>
                                    </Card>
                                </Col>
                                <Col md="6">
                                    <div className="pl-md-5">
                                        <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                                            <i className="ni ni-delivery-fast" />
                                        </div>
                                        <h3>Private Operators</h3>
                                        <p className="">
                                        <i className="ni ni-check-bold mr-2 text-warning" />Offer more digital payment options to your parkers and manage them all from one centralized system
</p>

                                        <p><i className="ni ni-check-bold mr-2 text-warning" />Enjoy seamless integrations that connect the other technologies you currently use to ZoneIt's solutions
</p>
<p>

                                        <i className="ni ni-check-bold mr-2 text-warning" />Make more informed decisions and drive revenue by utilizing real-time data
                                        </p>
                                        <p
                                            className="font-weight-bold text-warning mt-5"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Customized Automation & Efficiency for your needs
                                        </p>
                                        <Badge color="warning" pill className="mr-1">
                                            Municipalities
                                        </Badge>
                                        <Badge color="warning" pill className="mr-1">
                                            Universities
                                        </Badge>
                                        <Badge color="warning" pill className="mr-1">
                                            Private Operators
                                        </Badge>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    
}

export default PrivateOperators;
