import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import {sendContactForm} from '../../api'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

const contactInitialState = {
    ProductOfInterest: 'Mobile Pay',
    Name: "",
    BusinessName: "",
    Number: "",
    Email: ""
  }


export const ContactUs = (props) => {
    const [contact, setContact] = useState(contactInitialState);
    const [contactIsValid, setContactIsValid] = useState(false);
    const [phoneNumberFocused, setPhoneNumberFocused] = useState(false);
    const [emailFocused, setEmailFocused] = useState(false);
    const [businessFocused, setBusinessFocused] = useState(false);
    const [nameFocused, setNameFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e) => {
        const newContact = {...contact, [e.target.name]: e.target.value};
        if(newContact.Name !== "" && newContact.BusinessName !== "" && newContact.Email !== "" && newContact.Number !== ""){
            setContactIsValid(true);
        } else {
            setContactIsValid(false);
        }
        setContact(newContact);
      }
      const _sendContactForm = async () =>{
        try {
          setIsLoading(true)
          await sendContactForm(this.state.contact);
        } catch (e) {
          console.error(e)
        } finally {
          console.log('finally')
          this.setState({
            contact: contactInitialState,
            isLoading: false,
            contactIsValid: false
          })
        }
    
      }
      return (
        <Container id="form-contact-us">
              <Row className="justify-content-center">
                <Col lg={props.size || '8'}>
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to work with us?</h4>
                      <p className="mt-0">
                        Your project is very important to us.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-single-02 mr-2" />
                              Your name
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={contact.Name || ""}
                            type="text"
                            name="Name"
                            onChange={handleChange}
                            onFocus={() => setNameFocused(true)}
                            onBlur={() => setNameFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: businessFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-building mr-2" />
                              Business name
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={contact.BusinessName || ""}
                            type="text"
                            name="BusinessName"
                            onChange={handleChange}
                            onFocus={() => setBusinessFocused(true)}
                            onBlur={() => setBusinessFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83 mr-2" />
                              Email address
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={contact.Email || ""}
                            type="email"
                            name="Email"
                            onChange={handleChange}
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: phoneNumberFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button mr-2" />
                              Phone number 
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={contact.Number || ""}
                            type="tel"
                            name="Number"
                            onChange={handleChange}
                            onFocus={() => setPhoneNumberFocused(true)}
                            onBlur={() => setPhoneNumberFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="custom-control custom-radio mb-3">
          <input
            className="custom-control-input"
            value={'Mobile Pay'}
            id="radio-mobile-pay"
            name="ProductOfInterest"
            type="radio"
            onChange={handleChange}
            checked={contact.ProductOfInterest === 'Mobile Pay'}
          />
          <label className="custom-control-label" htmlFor="radio-mobile-pay">
            Mobile Pay
          </label>
        </div>
                      <div className="custom-control custom-radio mb-3">
          <input
            className="custom-control-input"
            checked={contact.ProductOfInterest === 'Digital Permits'}
            id="radio-digital-permits"
            name="ProductOfInterest"
            value={'Digital Permits'}
            type="radio"
            onChange={handleChange}
          />
          <label className="custom-control-label" htmlFor="radio-digital-permits">
            Digital Permits
          </label>
        </div>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="warning"
                          size="lg"
                          type="button"
                          disabled={!contactIsValid || isLoading }
                          onClick={_sendContactForm}
                        >
                            {isLoading ? (<>
    <Spinner
      style={{ width: "1rem", height: "1rem" }}
      type="grow"
      color="light"
      className="mr-2"
      />
    <Spinner
      className="mr-2"
      style={{ width: "1rem", height: "1rem" }}
      type="grow"
      color="light"
    />
    <Spinner
      style={{ width: "1rem", height: "1rem" }}
      type="grow"
      color="light"
    /></>
  ) : "Send Message" }
                          
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
      )
}

export default ContactUs