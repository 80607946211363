import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
import Landing from "views/Landing.js";
import Municipalities from "views/Inudstries.Municipalities";
import Universities from "views/Inudstries.Universities";
import PrivateOperators from "views/Inudstries.PrivateOperators";
import WhyZoneIt from "views/About.WhyZoneIt";
// import Experts from "views/About.Experts";
// import Security from "views/About.Security";

ReactDOM.render(
  <>
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Landing {...props} />} />
      <Route path="/industries/municipalities" exact render={props => <Municipalities {...props} />} />
      <Route path="/industries/universities" exact render={props => <Universities {...props} />} />
      <Route path="/industries/private-operators" exact render={props => <PrivateOperators {...props} />} />
      <Route path="/about/why-zone-it" exact render={props => <WhyZoneIt {...props} />} />
      {/* <Route path="/about/security" exact render={props => <Security {...props} />} />
      <Route path="/about/experts" exact render={props => <Experts {...props} />} /> */}
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </>,
  document.getElementById("root")
);
