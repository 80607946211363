import axios from 'axios';
import config from './constants'

const client = axios.create({
    baseURL: config.apiEndpoint,
});

export const sendContactForm = (data) => {
    const options = {
        url: 'contact',
        method: 'post',
        data,
    }
    return client(options);
}