import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import ContactUs from "components/Forms/ContactUs";
import { doScrolling } from "../utilities";

// index page sections
import Icons from "./IndexSections/Icons.js";

class Landing extends React.Component {

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default" style={{background: `linear-gradient(150deg, #fb6b40 15%, #fb6b40 70%, #fba73f 94%)`}}>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-2 text-white">
                        One powerful platform{" "}
                        <span classnames="text-uppercase">For All Your Parking Management</span>
                      </h1>
                      <p className="lead text-white">
                      Use ZoneIt's powerful platform to manage mobile pay parking, parking enforcement, digital permitting, payments and more. Centralize your parking data to operate more efficiently, increase revenue, and improve the parking experience with an integrated solution.
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-white mb-3 mb-sm-0"
                          color="default"
                          onClick={() => doScrolling("form-contact-us", 1500)}
                        >
                          <span className="btn-inner--text">Schedule a Demo</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          onClick={() => doScrolling("container-platform", 1500)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-app" />
                          </span>
                          <span className="btn-inner--text">
                            Explore ZoneIt
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg pt-lg-0 mt--100">
            <Icons />
            </section>
          <section className="section section-lg pt-lg-0">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-mobile" style={{fontSize: '2rem'}} />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Mobile Pay
                          </h6>
                          <p className="description mt-3">
                          <span className="lead my-0">Robust Rate Engine</span>
                          <br />
The industry’s most sophisticated rate engine with real-time rate and restriction management.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              analytics
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              rate engine
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              payments
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Android
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              iOS
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-badge" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Digital Permits
                          </h6>
                          <p className="description mt-3">
                          <span className="lead my-0">Improved Experience</span>
                          <br />
                          Stop using spreadsheets and outdated tools to manage and issue permits. Equip your team with powerful tools and data.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              management
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              payments
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              efficiency
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              real-time
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-app" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Platform
                          </h6>
                          <p className="description mt-3">
                          <span className="lead my-0">Engine</span>
                          <br />
                          Best-in-class digital products including mobile pay parking, enforcement, permitting, and payments.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              integrations
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              efficiency
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              data-driven
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              analytics
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section mb-5 bg-gradient-warning">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-mobile-button text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">ZoneIt&#xAE; Mobile Pay</h4>
                      <p className="text-white">
                      Seamless digital parking options for your customers, simple parking management for your team.
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-satisfied" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">
                            Awesome Support
                          </h5>
                          <p>
                          Access to customer parking information that enables you to provide best-in-class customer support.
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-settings-gear-65" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">
                          Robust Rate Engine
                          </h5>
                          <p>
                          The industry’s most sophisticated rate engine with real-time rate and restriction management
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section bg-secondary mb-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/img-1-1200x1000.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                      The ZoneIt&#xAE; Difference
                      </h4>
                      <p className="lead text-italic text-white">
ZoneIt's digital platform is best suited to meet the unique needs of your organization.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-building" />
                    </div>
                    <h3>Our customers</h3>
                    <p className="">
                    Cities are growing rapidly and facing new challenges. ZoneIt's platform is trusted by cities to manage digital payments for parking, enforcement and permitting, providing them with real-time mobility data in a centralized place.
                    </p>
                    <p>
                    Simplify your campus’ parking management operations with ZoneIt's digital platform.
                    </p>
                    <p
                      className="font-weight-bold text-warning mt-5"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      Customized Automation & Efficiency for your needs
                    </p>
                    <Badge color="warning" pill className="mr-1">
                              Municipalities
                            </Badge>
                    <Badge color="warning" pill className="mr-1">
                              Universities
                            </Badge>
                    <Badge color="warning" pill className="mr-1">
                              Private Operators
                            </Badge>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-warning">
                <ContactUs size="8" />
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
